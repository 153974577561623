
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  @apply bg-default-dark; 
}

body {
  font-family: theme('fontFamily.sans');
  font-weight: 300;
}

.hero {
  background:url('/public/bg-2.jpg') 0 0 no-repeat;
  background-size: cover;
  background-position: center left;

  @screen md {
    background-position: center center;
  }
}

button {
  @apply rounded-lg bg-gradient-to-r from-primary-2 to-primary-1 px-5 py-3 text-white shadow-lg font-bold;
}

p a {
  color: theme('colors.primary-1');
  text-decoration: underline;
}

.gradient-text {
  background: linear-gradient(to right, theme('colors.primary-2'), theme('colors.primary-1'));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}